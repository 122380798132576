import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import MainApp from "./mainApp";

import * as serviceWorker from "./serviceWorker";

import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";

//reducers
import appReducer from "./reducers/appReducer";
import systemReducer from "./reducers/systemReducer";

// const estado = {

// }

const initialState = {
  system: {
    system_type: "",
    system_os: "",
    browser: "",
    browser_version: ""
  },
  app: {
    acive_view: "inicio",
    sub_view: ""
  }
};

const allReducers = combineReducers({
  system: systemReducer,
  app: appReducer
});

const store = createStore(
  allReducers,
  initialState,
  window.devToolsExtension && window.devToolsExtension()
);

ReactDOM.render(
  <Provider store={store}>
    <MainApp />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
